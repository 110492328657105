import Vue from 'vue'
import Vuex from 'vuex'
import $cookie from 'js-cookie'
import {USER_DATA, ISDETAIL} from '@/utils/StoreKey.js'
import $https from '../api/https'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: $cookie.get(USER_DATA) || null,
        config: {
            img_base_url: 'https://ddimage.topboom0912.com/'
        }, // 配置项
        route: [], // 路由面包屑列表 Array
        categoryData: [], // 类目管理
        categoryOptions: [], // 通用分类列表
        brandOptions: [], // 通用品牌列表
        vip: [], // 用户等级
        isDetail: $cookie.get(ISDETAIL), // 公告展示详情还是编辑
        jumpOptions: [
            {
                type: 1,
                name: '商品'
            },
            {
                type: 2,
                name: '商品专题'
            },
            // {
            //     type: 3,
            //     name: '品牌'
            // },
            {
                type: 4,
                name: '三级分类'
            },
            {
                type: 5,
                name: '二级分类'
            },
            {
                type: 6,
                name: '一级分类'
            },
            {
                type: 7,
                name: '小程序内页面'
            },
            {
                type: 8,
                name: 'h5'
            },
            {
                type: 9,
                name: '不跳转'
            }, 
            // {
            //     type: 10,
            //     name: '抽奖活动'
            // }
        ]
    },
    mutations: {
        setUserInfo(state, user) {
            const same = JSON.parse(JSON.stringify(user))
            for (const key in same) {
                if (Object.prototype.hasOwnProperty.call(same, key)) {
                    same[key.toUpperCase()] = same[key]
                    delete same[key]
                }
            }
            $cookie.set(USER_DATA, JSON.stringify(same), {expires: 6}) // 设置三天的过期时间
            state.userInfo = same
        },
        changeRoute(state, page) {
            const pagebk = JSON.parse(JSON.stringify(page))
            if (!pagebk) {
                state.route = []
                return
            }
            if (page.link.indexOf('?') !== -1) pagebk.realLink = page.link.slice(0, page.link.indexOf('?'))
            const index = state.route.findIndex(item => item.link === pagebk.link)
            if (index !== -1) {
                state.route = state.route.slice(0, index + 1)
                return
            }
            if (!pagebk.name) {
                state.route = []
                return
            }
            state.route.push(pagebk)
        },
        setCategoryOptions(state, list) {
            const dealData = (arr) => {
                arr.forEach(item => {
                    item.checked = false
                    if (item.children) dealData(item.children)
                })
                return arr
            }
            if (!list) {
                state.categoryOptions = dealData(state.categoryOptions)
                return
            }
            state.categoryOptions = [...list]
        },
        setBrandOptions(state, list) {
            state.brandOptions = [...list]
        },
        setConfig(state, config) {
            state.config = config
        },
        setVip(state, vip) {
            state.vip = vip
        },
        setCategroy(state, data) {
            if (!data) {
                state.categoryData = []
                return
            }
            const idx = state.categoryData.findIndex(item => item._type === data._type)
            if (idx !== -1) {
                if (data.id) {
                    state.categoryData.splice(idx, 1, data)
                } else {
                    state.categoryData = state.categoryData.slice(0, idx)
                }
            } else {
                if (data.id) state.categoryData.push(data)
            }
        },
        showDetail(state, boolean) {
            $cookie.set(ISDETAIL, boolean)
            state.isDetail = boolean
        }
    },
    actions: {
        getCommonApi({commit}) {
            const categoryUrl = 'admin/home/category/list'
            const brandUrl = 'admin/home/brand/list'
            const configUrl = 'admin/home/config/list'
            const vipUrl = 'admin/home/vip/list'
            const dealData = (arr, type) => {
                arr.forEach(item => {
                    item.value = item.id
                    item.label = type === 1 ? item.categoryName : item.brandName
                    item.checked = false
                    if (item.child) {
                        item.children = item.child
                        dealData(item.children, type)
                    }
                })
                return arr
            }
            $https.get(categoryUrl).then(data => {
                commit('setCategoryOptions', dealData(data, 1))
            })
            $https.get(brandUrl).then(data => {
                commit('setBrandOptions', dealData(data, 2))
            })
            $https.get(configUrl).then(data => {
                commit('setConfig', data)
            })
            $https.get(vipUrl).then(data => {
                commit('setVip', data)
            })
        }
    },
    modules: {}
})
