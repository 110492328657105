import store from '../store'

function dealImage(img) {
    if (!img) return ''
    const source = JSON.parse(img)
    if (Array.isArray(source)) {
        source.forEach(item => {
            item.url = dealUrl(item.url)
        })
        return source
    } else {
        source.url = dealUrl(source.url)
        return source.url
    }
}

function dealVideo(video) {
    if (!video) return ''
    const source = JSON.parse(video)
    source.url = dealUrl(source.url)
    return source
}

function dealUrl(url) {
    if (!url) return ''
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) return url
    const IMG_BASE_URL = store.state.config.img_base_url
    if (url.indexOf('/') === 0) url = url.subString(1)
    url = IMG_BASE_URL + url
    return url
}

export {dealImage, dealVideo, dealUrl}
