<template>
  <el-container style="width: 100%; height: 100%;">
    <el-aside width="200px" class="tb-aside">
      <div class="logo">
        <img src="../assets/menu_logo.png" alt="logo" width="125px" />
      </div>
      <el-menu
        router
        unique-opened
        :default-active="activeLink"
        background-color="#001529"
        class="tb-menu"
        text-color="rgba(256, 256, 256, 0.65)"
        active-text-color="#ffffff"
        @select="menuSelect"
      >
        <template v-for="(item, a) in menus">
          <el-menu-item :index="item.menu_url" :key="a" v-if="item.children.length === 0">
            <template slot="title">
              <img :src="item.icon" v-if="item.icon" width="14px" alt="icon" />
              {{item.menu_name}}
            </template>
          </el-menu-item>
          <el-submenu :index="item.id" :key="a" active-text-color="#fff" v-else>
            <template slot="title">
              <img :src="item.icon" v-if="item.icon" width="14px" alt="icon" />
              {{item.menu_name}}
            </template>
            <template v-for="(son, b) in item.children">
              <el-menu-item :index="son.menu_url" :key="b" v-if="son.children.length === 0">
                {{son.menu_name}}
              </el-menu-item>
              <el-submenu :index="son.id" :key="b" active-text-color="#fff" v-else>
                {{son.menu_name}}
                <el-menu-item v-for="(grandson, c) in son.children" :key="c" :index="grandson.menu_url">
                  {{grandson.menu_name}}
                </el-menu-item>
              </el-submenu>
            </template>
          </el-submenu>
        </template>
      </el-menu>
    </el-aside>
    <el-container class="tb-container">
      <el-header height="64px" class="tb-header">
        <div class="head flex">
          <el-dropdown @command="userClick">
            <div class="flex-center">
              <el-avatar :size="24" icon="el-icon-user-solid"></el-avatar>
              <span class="user-name" v-if="user.USERNAME">{{user.USERNAME}}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="forgetpwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="loingout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="tb-main">
        <router-view />
      </el-main>
    </el-container>
    <el-alert
      type="warning"
      class="tb-alert"
      :closable="false"
      v-if="message"
    >
      <template slot="title">
        <div class="tb-message-warning">
          <img src="../assets/icon/warning.png" class="tb-icon-warning" alt="">
          <span>尊敬的商家，您当前还有</span>
          <span class="import-tips">{{orders}}件</span>
          <span>待发货，</span>
          <span class="import-tips cursor" @click="toOrder">点击查看</span>
        </div>
      </template>
    </el-alert>
  </el-container>
</template>

<script>
import {mapState} from 'vuex'
import $cookie from 'js-cookie'
import {USER_DATA} from '@/utils/StoreKey.js'
export default {
  name: 'Index',
  data() {
    return {
      activeLink: 'home',
      menus: [],
      message: false,
      orders: 0
    }
  },
  computed: mapState({
    user: state => {
      const user = state.userInfo
      if (typeof user === 'string') {
        return JSON.parse(state.userInfo)
      }
      if (typeof user === 'object') {
        return state.userInfo
      }
      return {}
    }
  }),
  watch: {
    '$route': {
      immediate: true,
      handler: 'dealUnpack'
    }
  },
  mounted() {
    this.getMenu()
    const _this = this
    this.$router.beforeEach((to, from, next) => {
      if (from && from.path === '/login') {
        _this.getMenu()
      }
      let mappedList = _this.getMappedMenu(to.path, _this.menus)
      if (to.path === '/home' && mappedList.length === 0) {
        if (_this.menus.length > 0) {
          let menu = _this.getFirstRoutePath(_this.menus)
          if (menu === null) {
            next(false)
          } else {
            next({path: menu.menu_url})
          }
        } else {
          next(false)
        }
      } else {
        next()
      }
    })
    this.$store.dispatch('getCommonApi')
  },
  methods: {
    getFirstRoutePath(menus) {
      for(let i=0;i<menus.length;i++) {
        let item = menus[i]
        if (item.children.length === 0) {
          return item
        } else {
          let menu = this.getFirstRoutePath(item.children)
          return menu
        }
      }
      return null
    },
    getMappedMenu(path, menu) {
      let list = menu.filter(item => {
        if (item.children.length > 0) {
          let children = this.getMappedMenu(path, item.children)
          if (children.length > 0) {
            return true
          } else {
            return false
          }
        } else {
          if (item.menu_url === path) {
            return true
          } else {
            return false
          }
        }
      })
      return list
    },
    async getMenu() {
      const url = 'admin/user/menu'
      const data = await this.$https.get(url)
      const dealMenu = (arr) => {
        if(!Array.isArray(arr)) return []
        arr.forEach(item => {
          item.icon = this.$img(item.menu_icon)
          item.id += ''
          if (item.children && item.children.length !== 0) dealMenu(item.children)
        })
        return arr
      }
      if (data) {
        this.menus = dealMenu(data)
        const _this = this
        let to = this.$route
        let mappedList = _this.getMappedMenu(to.path, _this.menus)
        if (to.path === '/home' && mappedList.length === 0) {
          if (_this.menus.length > 0) {
            let menu = _this.getFirstRoutePath(_this.menus)
            if (menu !== null) {
              this.$router.replace({path: menu.menu_url})
            }
          }
        }
      }
    },
    async getUnpack() {
      const url = 'admin/home/order/unpack/count'
      this.$https.get(url).then(data => {
        if (data && data * 1 !== 0) {
          this.orders = data * 1
          this.message = true
        } else {
           this.orders = 0
          this.message = false
        }
      })
    },
    dealUnpack() {
      if (!this.message) this.getUnpack()
    },
    toOrder() {
      this.$router.push({name: 'OrderList', params: { status: '1' }})
    },
    userClick(item) {
      if (item === 'loingout') {
        $cookie.remove(USER_DATA)
        this.$router.replace('/login')
      }
      if (item === 'forgetpwd') {
        $cookie.remove(USER_DATA)
        this.$router.replace('/getpwd')
      }
    },
    menuSelect(item) {
      if (item === '/notice/list') {
        // 因为没有做商家和管理的区别，所以只能从菜单判断展示详情还是编辑
        this.$store.commit('showDetail', 'false')
      }
    }
  }
}
</script>

<style lang="scss">
.tb-aside {
  background-color: #001529;
  box-shadow: 2px 0 6px 0  rgba(0,21,41,0.35);
  .logo {
    width: 100%;
    padding: 16px 0 16px 24px;
    box-sizing: border-box;
  }
  .tb-menu {
    border: none;
  }
}
.tb-container {
  min-width: 1200px;
  background-color: #F0F2F5;
  .tb-header {
    background-color: #FFF;
    box-shadow: 0 1px 4px 0  rgba(0, 21, 41, 0.12);
    z-index: 5;
    .head {
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      .user-name {
        padding-left: 8px;
        line-height: 22px;
        color: rgba($color: #000000, $alpha: 0.65);
      }
    }
  }
  .tb-main {
    padding: 0;
    box-sizing: border-box;
  }
}
.tb-alert {
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 616px;
  height: 40px;
  z-index: 99;
  border-radius: 4px;
  border: 1px solid #FFE58F;
  box-sizing: border-box;
  .el-alert__title {
    font-size: inherit;
    line-height: inherit;
  }
  .tb-message-warning {
    display: flex;
    align-items: center;
    color: rgba($color: #000000, $alpha: 0.65);
    background-color: #FFFBE6;
    .tb-icon-warning {
      width: 13px;
      height: 13px;
      margin-right: 8px;
    }
  }
  .import-tips {
    color: #1890FF;
  }
}
</style>