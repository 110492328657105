import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: '/home',
        component: index,
        children: [{
            path: 'home',
            name: 'Home',
            component: () => import('../views/home/Home.vue')
        }, {
            path: 'brand',
            name: 'Brand',
            redirect: '/brand/list',
            component: () => import('../views/brand/Index.vue'),
            children: [{
                path: '/brand/list',
                name: 'BrandList',
                component: () => import('../views/brand/List.vue')
            }, {
                path: '/brand/add',
                name: 'BrandAdd',
                component: () => import('../views/brand/Add.vue')
            }]
        }, {
            path: 'categroy',
            name: 'Categroy',
            redirect: '/categroy/first',
            component: () => import('../views/categroy/Index.vue'),
            children: [{
                path: '/categroy/first',
                name: 'CategroyFirst',
                component: () => import('../views/categroy/First.vue')
            }, {
                path: '/categroy/second',
                name: 'CategroySecond',
                component: () => import('../views/categroy/Second.vue')
            }, {
                path: '/categroy/third',
                name: 'CategroyThird',
                component: () => import('../views/categroy/Third.vue')
            }, {
                path: '/categroy/add',
                name: 'CategroyAdd',
                component: () => import('../views/categroy/Add.vue')
            }]
        }, {
            path: 'product',
            name: 'Product',
            redirect: '/product/list',
            component: () => import('../views/product/Index.vue'),
            children: [{
                path: '/product/list',
                name: 'ProductList',
                component: () => import('../views/product/List.vue')
            }, {
                path: '/product/add',
                name: 'ProductAdd',
                component: () => import('../views/product/Add.vue')
            }, {
                path: '/product/publish',
                name: 'ProductPublish',
                component: () => import('../views/product/Publish.vue')
            }]
        }, {
            path: 'order',
            name: 'Order',
            redirect: '/order/list',
            component: () => import('../views/order/Index.vue'),
            children: [{
                path: '/order/list',
                name: 'OrderList',
                component: () => import('../views/order/List.vue')
            }, {
                path: '/order/detail',
                name: 'OrderDetail',
                component: () => import('../views/order/Detail.vue')
            }]
        }, {
            path: 'refund',
            name: 'Refund',
            redirect: '/refund/list',
            component: () => import('../views/refund/Index.vue'),
            children: [{
                path: '/refund/list',
                name: 'RefundList',
                component: () => import('../views/refund/List.vue')
            }, {
                path: '/refund/detail',
                name: 'RefundDetail',
                component: () => import('../views/refund/Detail.vue')
            }]
        }, {
            path: 'coupon',
            name: 'Coupon',
            redirect: '/coupon/list',
            component: () => import('../views/coupon/Index.vue'),
            children: [{
                path: '/coupon/list',
                name: 'CouponList',
                component: () => import('../views/coupon/List.vue')
            }, {
                path: '/coupon/add',
                name: 'CouponAdd',
                component: () => import('../views/coupon/Add.vue')
            }]
        }, {
            path: 'thema',
            name: 'Thema',
            redirect: '/thema/list',
            component: () => import('../views/thema/Index.vue'),
            children: [{
                path: '/thema/list',
                name: 'ThemaList',
                component: () => import('../views/thema/List.vue')
            }, {
                path: '/thema/add',
                name: 'ThemaAdd',
                component: () => import('../views/thema/Add.vue')
            }]
        }, {
            path: 'homebanner',
            name: 'HomeBanner',
            redirect: '/homebanner/list',
            component: () => import('../views/active/homeBanner/Index.vue'),
            children: [{
                path: '/homebanner/list',
                name: 'HomeBannerList',
                component: () => import('../views/active/homeBanner/List.vue')
            }, {
                path: '/homebanner/add',
                name: 'HomeBannerAdd',
                component: () => import('../views/active/homeBanner/Add.vue')
            }]
        }, {
            path: 'categroybanner',
            name: 'CategroyBanner',
            redirect: '/categroybanner/list',
            component: () => import('../views/active/categroyBanner/Index.vue'),
            children: [{
                path: '/categroybanner/list',
                name: 'CategroyBannerList',
                component: () => import('../views/active/categroyBanner/List.vue')
            }, {
                path: '/categroybanner/add',
                name: 'CategroyBannerAdd',
                component: () => import('../views/active/categroyBanner/Add.vue')
            }]
        }, {
            path: 'indexFlow',
            name: 'IndexFlow',
            redirect: '/indexflow/list',
            component: () => import('../views/indexflow/Index.vue'),
            children: [{
                path: '/indexflow/list',
                name: 'IndexFlowList',
                component: () => import('../views/indexflow/List.vue')
            }, {
                path: '/indexflow/add',
                name: 'IndexFlowAdd',
                component: () => import('../views/indexflow/Add.vue')
            }]
        }, {
            path: 'homeactivebanner',
            name: 'HomeActiveBanner',
            component: () => import('../views/active/HomeActiveBanner.vue')
        }, {
            path: 'kingkong',
            name: 'Kingkong',
            component: () => import('../views/kingkong/Kingkong.vue')
        }, {
            path: 'integral',
            name: 'Integral',
            redirect: '/integral/list',
            component: () => import('../views/integral/Index.vue'),
            children: [{
                path: '/integral/list',
                name: 'IntegralList',
                component: () => import('../views/integral/List.vue')
            }, {
                path: '/integral/add',
                name: 'IntegralAdd',
                component: () => import('../views/integral/Add.vue')
            }]
        }, {
            path: '/integral/record',
            name: 'IntegralRecord',
            component: () => import('../views/integral/Record.vue')
        }, {
            path: 'notice',
            name: 'Notice',
            redirect: '/notice/list',
            component: () => import('../views/notice/Index.vue'),
            children: [{
                path: '/notice/list',
                name: 'NoticeList',
                component: () => import('../views/notice/List.vue')
            }, {
                path: '/notice/add',
                name: 'NoticeAdd',
                component: () => import('../views/notice/Add.vue')
            }, {
                path: '/notice/detail',
                name: 'NoticeDetail',
                component: () => import('../views/notice/Detail.vue')
            }]
        }, {
            path: 'member',
            name: 'Member',
            redirect: '/member/list',
            component: () => import('../views/member/Index.vue'),
            children: [{
                path: '/member/list',
                name: 'MemberList',
                component: () => import('../views/member/List.vue')
            }, {
                path: '/member/detail',
                name: 'MemberDetail',
                component: () => import('../views/member/Detail.vue')
            }]
        }, {
            path: '/user/list',
            name: 'UserList',
            component: () => import('../views/user/List.vue')
        }, {
            path: '/menu/list',
            name: 'MenuList',
            component: () => import('../views/menu/List.vue')
        }, {
            path: '/role/list',
            name: 'RoleList',
            component: () => import('../views/role/List.vue')
        }, {
            path: '/import/index',
            name: 'ImportTaobao',
            component: () => import('../views/import/Index.vue')
        }, {
            path: 'lottery',
            name: 'Lottery',
            redirect: '/lottery/list',
            component: () => import('../views/lottery/Index.vue'),
            children: [{
                path: "/lottery/list",
                name: 'LotteryList',
                component: () => import('../views/lottery/List')
            }, {
                path: "/lottery/add",
                name: 'LotteryAdd',
                component: () => import('../views/lottery/Add')
            }]
        }, {
            path: "/lottery/lucky",
            name: 'LotteryLucky',
            component: () => import('../views/lottery/Lucky')
        }, {
            path: 'feed',
            name: "Feed",
            redirect: '/feed/list',
            component: () => import('../views/feed/Index.vue'),
            children: [{
                path: '/feed/list',
                name: 'FeedList',
                component: () => import('../views/feed/List.vue')
            }, {
                path: '/feed/audit',
                name: 'FeedAudit',
                component: () => import('../views/feed/audit.vue')
            }]
        }, {
            path: 'advertis',
            name: "Advertis",
            redirect: '/advertis/list',
            component: () => import('../views/advertis/Index.vue'),
            children: [{
                path: '/advertis/list',
                name: 'AdvertisList',
                component: () => import('../views/advertis/List.vue')
            }, {
                path: '/advertis/add',
                naem: 'AdvertisAdd',
                component: () => import('../views/advertis/Add.vue')
            }]
        }, {
            path: 'feedTopic',
            name: "FeedTopic",
            redirect: '/feedTopic/list',
            component: () => import('../views/feedTopic/Index.vue'),
            children: [
                {
                    path: '/feedTopic/list',
                    name: 'FeedTopicList',
                    component: () => import('../views/feedTopic/List.vue')
                },
                {
                    path: '/feedTopic/add',
                    name: 'FeedTopicAdd',
                    component: () => import('../views/feedTopic/Add.vue')
                }
            ]
        }, {
            path: 'feedComment',
            name: "FeedComment",
            redirect: '/feedComment/list',
            component: () => import('../views/feedComment/Index.vue'),
            children: [
                {
                    path: '/feedComment/list',
                    name: 'FeedCommentList',
                    component: () => import('../views/feedComment/List.vue')
                }
            ]
        }, {
            path: 'feedReport',
            name: "FeedReport",
            redirect: '/feedReport/list',
            component: () => import('../views/feedReport/Index.vue'),
            children: [
                {
                    path: '/feedReport/list',
                    name: 'FeedReportList',
                    component: () => import('../views/feedReport/List.vue')
                },
                {
                    path: '/feedReport/edit',
                    name: 'FeedReportEdit',
                    component: () => import('../views/feedReport/Edit.vue')
                }
            ]
        }, {
            path: "SellerConfig",
            name: 'SellerConfig',
            component: () => import('../views/seller/SellerConfig')
        }, {
            path: "SellerProduct",
            name: 'SellerProduct',
            component: () => import('../views/seller/SellerProduct')
        }, {
            path: "SellerManage",
            name: "SellerManage",
            redirect: "/SellerManage/list",
            component: () => import('../views/seller/SellerManageIndex'),
            children: [
                {
                    path: "/SellerManage/list",
                    name: "SellerList",
                    component: () => import('../views/seller/SellerManageList')
                }, {
                    path: '/SellerManage/add',
                    name: 'SellerAdd',
                    component: () => import('../views/seller/SellerAdd')
                }, {
                    path: '/SellerManage/withdrawList',
                    name: 'WithdrawList',
                    component: () => import('../views/seller/WithdrawList')
                }, {
                path: '/SellerOrderList',
                name: 'SellerOrderList',
                component: () => import('../views/seller/SellerOrderList')
              }
            ]
        }, {
            path: '/SellerWithdraw',
            name: 'SellerWithdraw',
            component: () => import('../views/seller/CommWithdraw')
        }, {
            path: '/WithdrawList',
            name: 'SellerWithdrawList',
            component: () => import('../views/seller/SellerWithdrawList')
        }]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/Login.vue')
    },
    {
        path: '/getpwd',
        name: 'Getpwd',
        component: () => import('../views/login/Findpwd.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
