import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import './style/element-variables.scss'
import router from './router'
import store from './store'
import https from './api/https'
import dayjs from 'dayjs'
import np from 'number-precision'
import {dealImage, dealVideo, dealUrl} from './utils/dealMaterial'

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$https = https
Vue.prototype.$dayjs = dayjs
Vue.prototype.$np = np
Vue.prototype.$img = dealImage
Vue.prototype.$video = dealVideo
Vue.prototype.$dealUrl = dealUrl

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
