/*
 * @Author: your name
 * @Date: 2020-12-25 13:52:49
 * @LastEditTime: 2021-07-13 09:29:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dd-admin\src\api\https.js
 */
import axios from "axios";
import $cookie from "js-cookie";
import { USER_DATA } from "@/utils/StoreKey.js";
import { Message } from "element-ui";
import router from "@/router/index";

const https = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://apitest.topboom0912.com/dd/"
      : "https://api.topboom0912.com/dd/",
  timeout: 5000
});

https.interceptors.request.use(
  config => {
    const USER = $cookie.get(USER_DATA) || null;
    let TOKEN = "";
    if (USER) {
      TOKEN = JSON.parse(USER).TOKEN;
      config.headers.tbadminauthorization = TOKEN;
    }
    for (const key in config.params) {
      const element = config.params[key];
      if (
        (typeof element === "string" && !element) ||
        element === null ||
        (Array.isArray(element) && element.length === 0)
      ) {
        delete config.params[key];
      }
      // if (typeof element === 'string' && !element) delete config.params[key]
      // if (element === null) delete config.params[key]
      // if (Array.isArray(element) && element.length === 0) delete config.params[key]
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

https.interceptors.response.use(
  response => {
    const urls = [
      "admin/user/login",
      "admin/user/verify/send",
      "admin/user/modify/pwd"
    ];
    if (urls.includes(response.config.url)) {
      return response.data;
    }
    if (response.data.code) {
      const code = response.data.code;
      const msg = response.data.msg || "服务器错误，请刷新后重试";

      let f = false;
      if (code === 502) {
        // 登陆超时
        Message.error(msg);
        $cookie.remove(USER_DATA);
        router.replace("/login");
      } else if (code !== 200) {
        Message.error(msg).f = true;
      }
      if (f) {
        return false;
      } else {
        return response.data.data ? response.data.data : 0;
      }
    } else {
      return response.data;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default https;
